body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'hamnosysunicoderegular';
  src: url('./assets/font/hamnosysunicode-webfont.eot');
  src: url('./assets/font/hamnosysunicode-webfont.eot?#iefix') format('embedded-opentype'), url('./assets/font/hamnosysunicode-webfont.woff2') format('woff2'), url('./assets/font/hamnosysunicode-webfont.woff') format('woff'), url('./assets/font/hamnosysunicode-webfont.ttf') format('truetype'), url('./assets/font/hamnosysunicode-webfont.svg#hamnosysunicoderegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
.hm-txt {
  font-family: "hamnosysunicoderegular";
  font-weight: 700;
  font-size: 3em !important;
}

.hm {
  width: 1.4em !important;
  height: 1.4em !important;
  background-color: white !important;
  font-family: "hamnosysunicoderegular" !important;
  font-size: xx-large !important;
  display: inline-block !important;
  position: relative !important;
  text-decoration: none !important;
  text-align: center !important;
  padding: 0 !important;
  font-weight: 700 !important;
  border-radius: 4px !important;
  border: 1px solid #bcbcbc !important;
  margin:2px !important;
}


.nm-hm {
  background-color: white !important;
  
  font-size: small !important;
  display: inline-block !important;
  position: relative !important;
  text-decoration: none !important;
  text-align: center !important;
  padding: 3px 5px !important;
  font-weight: 700 !important;
  border-radius: 4px !important;
  border: 1px solid #bcbcbc !important;
  margin:2px !important;
}
.carosule-key{
  width:"100%";
  height: '160px';
  color: '#fff';
  line-height: '160px';
  text-align: 'center';
  background: '#364d79';
}
.bg-orange{
  background: rgb(251 187 122);
}
.margin1{
  margin:5px;
}